import React, { useState, useCallback } from 'react';
import heic2any from 'heic2any';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useDropzone } from 'react-dropzone';
import AdSense from 'react-adsense';
import 'bootstrap/dist/css/bootstrap.min.css';

const HeicAndZipConverter = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [outputFormat, setOutputFormat] = useState('image/png');
  const [estimatedTime, setEstimatedTime] = useState(null);
  const [downloadMessage, setDownloadMessage] = useState('');

  const onDrop = useCallback((acceptedFiles) => {
    const heicFiles = acceptedFiles.filter(file => file.type === 'image/heic');
    const zipFiles = acceptedFiles.filter(file => file.name.endsWith('.zip'));

    if (zipFiles.length > 0) {
      setFiles(zipFiles);
      setEstimatedTime(null);
      setDownloadMessage('');
      estimateConversionTime(zipFiles);
    } else if (heicFiles.length > 0) {
      setFiles(heicFiles.slice(0, 20)); // Limit to 20 files
      setEstimatedTime(null);
      setDownloadMessage('');
      estimateConversionTime(heicFiles.slice(0, 20));
    } else {
      setError('Please upload HEIC files or a ZIP file containing HEIC files.');
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['.zip', 'image/heic'],
    onDrop,
    multiple: true,
    maxFiles: 20
  });

  const handleFormatChange = (event) => {
    setOutputFormat(event.target.value);
  };

  const estimateConversionTime = async (fileList) => {
    if (fileList.length === 0) return;

    console.log(fileList, 'list')
    const fileType = fileList[0].name.split('.').pop().toLowerCase();
    if (fileType === 'zip') {
      try {
        const zip = new JSZip();
        const zipData = await zip.loadAsync(fileList[0]);
        const heicFiles = zipData.filter((relativePath, file) => file.name.endsWith('.heic'));
        const numberOfFiles = heicFiles.length;
        const estimatedTime = (numberOfFiles * 1.5).toFixed(2); // Assuming 1.5 seconds per file
        setEstimatedTime(estimatedTime);
      } catch (err) {
        setError('Error reading the ZIP file.');
        console.error('ZIP file read error:', err);
      }
    } else if (fileType === 'heic') {
      const numberOfFiles = fileList.length;
      const estimatedTime = (numberOfFiles * 5).toFixed(2); // Assuming 1.5 seconds per file
      setEstimatedTime(estimatedTime);
    } else {
      setError('Unsupported file type. Please upload HEIC or ZIP files.');
    }
  };

  const convertAndDownload = async () => {
    if (files.length === 0) return;

    setLoading(true);
    setError(null);
    setEstimatedTime(null);
    setDownloadMessage('');

    const fileType = files[0].name.split('.').pop().toLowerCase();

    if (fileType === 'zip') {
      await handleZipFile();
    } else if (fileType === 'heic') {
      await handleHeicFiles(files);
    } else {
      setError('Unsupported file type. Please upload HEIC or ZIP files.');
    }

    setLoading(false);
  };

  const handleZipFile = async () => {
    const zip = new JSZip();
    const outputZip = new JSZip();

    try {
      const zipData = await zip.loadAsync(files[0]);
      const heicFiles = zipData.filter((relativePath, file) => file.name.endsWith('.heic'));
      const numberOfFiles = heicFiles.length;
      let totalConversionTime = 5;

      for (const file of heicFiles) {
        const startTime = performance.now();
        const heicBlob = await file.async('blob');
        const outputBlob = await heic2any({ blob: heicBlob, toType: outputFormat });
        const endTime = performance.now();
        const conversionTime = endTime - startTime;
        totalConversionTime += conversionTime;

        const extension = outputFormat.split('/')[1];
        outputZip.file(file.name.replace('.heic', `.${extension}`), outputBlob);
      }

      const averageTimePerFile = totalConversionTime / numberOfFiles;
      const estimatedTotalTime = averageTimePerFile * numberOfFiles;
      setEstimatedTime((estimatedTotalTime / 1000).toFixed(2)); // Convert to seconds and round off

      const outputZipBlob = await outputZip.generateAsync({ type: 'blob' });
      saveAs(outputZipBlob, `converted_images.${outputFormat.split('/')[1]}.zip`);
      setEstimatedTime('')
      setDownloadMessage('Download complete.');
    } catch (err) {
      setError('An error occurred during the conversion process.');
      console.error('Conversion error:', err);
    }
  };

  const handleHeicFiles = async (files) => {
    const outputZip = new JSZip();
    let totalConversionTime = 5000;

    try {
      for (const file of files) {
        const startTime = performance.now();
        const outputBlob = await heic2any({ blob: file, toType: outputFormat });
        const endTime = performance.now();
        const conversionTime = endTime - startTime;
        totalConversionTime += conversionTime;

        const extension = outputFormat.split('/')[1];
        outputZip.file(file.name.replace('.heic', `.${extension}`), outputBlob);
      }

      const averageTimePerFile = totalConversionTime / files.length;
      const estimatedTotalTime = averageTimePerFile * files.length;
      setEstimatedTime((estimatedTotalTime / 1000).toFixed(2)); // Convert to seconds and round off

      const outputZipBlob = await outputZip.generateAsync({ type: 'blob' });
      saveAs(outputZipBlob, `converted_images.${outputFormat.split('/')[1]}.zip`);
      setEstimatedTime('')
      setDownloadMessage('Download complete.');
    } catch (err) {
      setError('An error occurred during the conversion process.');
      console.error('Conversion error:', err);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card shadow-lg">
            <div className="card-body">
              <h2 className="card-title text-center text-primary">HEIC to Image Converter</h2>
              <div
                {...getRootProps({ className: 'dropzone border border-secondary rounded p-5 mb-4 text-center bg-light' })}
                style={{ cursor: 'pointer' }}
              >
                <input {...getInputProps()} />
                <p className="text-muted mb-0">
                  {files.length > 0 ? `Selected files: ${files.map(file => file.name).join(', ')}` : 'Drag & drop HEIC or ZIP files here, or click to select files'}
                </p>
              </div>
              <div className="form-group">
                <label htmlFor="formatSelect">Select Output Format:</label>
                <select
                  id="formatSelect"
                  value={outputFormat}
                  onChange={handleFormatChange}
                  className="form-select"
                >
                  <option value="image/jpeg">JPEG</option>
                  <option value="image/png">PNG</option>
                  <option value="image/bmp">BMP</option>
                  <option value="image/webp">WEBP</option>
                  <option value="image/tiff">TIFF</option>
                  <option value="image/avif">AVIF</option>
                </select>
              </div>
              {estimatedTime && (
                <div className="alert alert-info" role="alert">
                  Image Processing is a time taking process so sit back and relax,
                  Estimated conversion time: {estimatedTime} seconds
                </div>
              )}
              <button
                id="submit-upload"
                onClick={convertAndDownload}
                disabled={files.length === 0 || loading}
                className="btn btn-primary btn-block mt-3"
              >
                {loading ? (
                  <span>
                    <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    Converting...
                  </span>
                ) : (
                  'Convert and Download'
                )}
              </button>
              {error && <p className="text-danger mt-3">{error}</p>}
              {downloadMessage && <p className="text-success mt-3">{downloadMessage}</p>}
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-4">
        <div className="col-md-4">
          <AdSense.Google
            client="ca-pub-6272472285844758" // Replace with your AdSense client ID
            slot="1105564389" // Replace with your AdSense slot ID
            style={{ display: 'block' }}
            format="auto"
            responsive="true"
          />
        </div>
        <div className="col-md-4">
          <AdSense.Google
            client="ca-pub-6272472285844758" // Replace with your AdSense client ID
            slot="1105564389" // Replace with your AdSense slot ID
            style={{ display: 'block' }}
            format="auto"
            responsive="true"
          />
        </div>
        <div className="col-md-4">
          <AdSense.Google
            client="ca-pub-6272472285844758" // Replace with your AdSense client ID
            slot="1105564389" // Replace with your AdSense slot ID
            style={{ display: 'block' }}
            format="auto"
            responsive="true"
          />
        </div>
      </div>
    </div>
  );
};

export default HeicAndZipConverter;
