import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const AboutUs = () => {
  return (
    <footer className="bg-dark text-white pt-5 pb-4">
    <div className="container text-center text-md-left">
      <div className="row text-center text-md-left">

        {/* About Us Section */}
        <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
          <h5 className="text-uppercase mb-4 font-weight-bold">About Us</h5>
          <p>
            We are dedicated to providing high-quality tools and services that make your life easier. Our mission is to offer reliable and easy-to-use solutions for everyday tasks.
          </p>
        </div>

        {/* Quick Links Section */}
        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
          <h5 className="text-uppercase mb-4 font-weight-bold">Quick Links</h5>
          <p>
            <a href="#" className="text-white" style={{ textDecoration: 'none' }}>Home</a>
          </p>
          <p>
            <a href="#" className="text-white" style={{ textDecoration: 'none' }}>Blog</a>
          </p>
        </div>

        {/* Contact Section */}
        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
          <h5 className="text-uppercase mb-4 font-weight-bold">Contact</h5>
          <p>
            <i className="fas fa-home mr-3"></i> Lahore,Punjab Pakistan
          </p>
          <p>
            <i className="fas fa-envelope mr-3"></i> tipuasad2001@gmail.com
          </p>
          <p>
            <i className="fas fa-phone mr-3"></i> +92 306 6683837
          </p>
          <p>
            <i className="fas fa-print mr-3"></i> +92 327 7862001
          </p>
        </div>

        {/* Social Media Section */}
        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
          <h5 className="text-uppercase mb-4 font-weight-bold">Follow Us</h5>
          <a href="https://github.com/H5SH" className="text-white me-4">
          <i class="bi bi-github"></i>
          </a>
          <a href="https://www.linkedin.com/in/hasham-asad-44ab9926a/" className="text-white me-4">
            <i className="bi bi-linkedin"></i>
          </a>
          {/* <a href="https://www.instagram.com/accounts/onetap/?next=%2F" className="text-white me-4">
            <i className="bi bi-instagram"></i>
          </a> */}
        </div>
      </div>

      <hr className="mb-4" />

      <div className="row align-items-center">
        <div className="col-md-7 col-lg-8">
          <p className="text-white">
            © 2024 Karbon. All Rights Reserved.
          </p>
        </div>

        <div className="col-md-5 col-lg-4">
          <p className="text-white text-md-right">
            Designed with <i className="fas fa-heart text-danger"></i> Karbon
          </p>
        </div>
      </div>
    </div>
  </footer>
  );
};

export default AboutUs;
