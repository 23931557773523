import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import HeicAndZipConverter from './app/HeicAndZipConverter';
import Blog from './app/Blog';
import AboutUs from './app/AboutUs';

function App() {
  return (
    <div className="App">
      <HeicAndZipConverter />
      {/* <Blog /> */}
      <AboutUs />
    </div>
  );
}

export default App;
